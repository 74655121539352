html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

:root {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
  "Helvetica Neue", sans-serif;
  font-size: calc(8px + 1vmin);
  --gray: #444957;
  --gray-light: #71798f;
  --gray-lightest: rgba(113, 121, 143, 0.1);
  --blue: #6188fb;
  --viewport--desktop: 1300px;
  --viewport--tablet: 1024px;
  --viewport--mobile: 300px;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
