.note {
  background: var(--blue);
  color: white;
  font-size: small;
  padding: .25rem 1rem;
  text-align: center;

  &--text-right {
    text-align: right;
  }
}
