.app {
  &-header {
    background: linear-gradient(50deg, var(--gray), var(--gray-light));
    min-height: 20vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;

    &-logo {
      height: 8vh;
    }
  }

  &-body {
    padding: calc(1rem + 2vmin);
    display: grid;
    grid-template-columns: 200px 1fr;
    column-gap: 2vmin;

    .toc {
      position: fixed;
      top: 1rem;
      left: 1rem;
      background: rgba(97, 136, 251, 0.8);
      border-radius: .5rem;
      padding: 1rem;
      font-size: small;
      display: flex;
      flex-direction: column;
      line-height: 1.5;

      h4 {
        color: whitesmoke;
        margin: 0 0 1rem 0;
        border-bottom: 1px solid whitesmoke;
      }

      a {
        text-decoration: none;
        color: whitesmoke;
      }
    }

    > * {
      grid-column: 2;
    }

    > h1 {
      grid-column: 1 / -1;
      color: var(--blue);
      border-bottom: 1px solid var(--gray-lightest);
    }

    > h2 {
      grid-column: 1 / -1;
      margin-top: 0;
      color: var(--gray-light);
      border-bottom: 1px solid var(--gray-lightest);
    }

    > .description {
      grid-column: 1;
    }
  }
}
