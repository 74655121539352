.design-component {

  &__viewport-handlers {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    opacity: .5;
    &:hover {
      opacity: .8;
    }
  }

  &__viewport-handle {
    font-size: 1rem;
    background: none;
    border: none;
    border-radius: .25rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;

    svg {
      fill: var(--gray-light);
    }

    &:nth-child(2) {
      transform: rotate(90deg);
    }

    &.is--active,
    &:hover {
      background: var(--gray-light);
      svg {
        fill: white;
      }
    }
  }

  iframe {
    box-shadow: 0 .5rem .5rem var(--gray-lightest);
    border: 1px solid var(--gray-lightest);
    border-radius: .5rem;
    margin-bottom: 2rem;
    padding: 2rem 0;
    transition: width 1s ease-in-out;

    &.large {
      min-height: 50vh;
      padding-bottom: 2rem;
    }

    &[data-viewport="mobile"] {
      width: var(--viewport--mobile);
    }

    &[data-viewport="tablet"] {
      width: var(--viewport--tablet);
    }

    &[data-viewport="desktop"] {
      width: var(--viewport--desktop);
    }
  }
}


